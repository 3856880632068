import React, { useEffect } from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";
import { agencyUnload, fetchAgencyBySlug } from "../redux/actions";
import { connect } from "react-redux";
import Loading from "../Components/Loading";
import NotFound from "./NotFound";

const CorporateEventBuyers = ({ match, fetchAgencyBySlug, agency, isFetchingAgency, agencyUnload }) => {
  useEffect(() => {
    fetchAgencyBySlug(match.params.agency);
    return () => agencyUnload();
  }, []);
  if (isFetchingAgency) {
    return <Loading />;
  }
  if (!agency || !agency.is_super_agent) {
    return <NotFound/>;
  }
  return (
    <RegistrationLandingPage
      img="/img/landing/conference-banner.jpg"
      title={agency ? `Register as a company with ${agency.name}` : "Find the perfect venue or meeting space for your next event"}
      subtitle="Connecting you globally with great meetings and events venues,
      giving you the EDGE when choosing your next venue."
      agency={agency}
      bannerFeatures={[
        {
          icon: "globe-americas",
          text: "19,000 venues",
        },
        {
          icon: "search",
          text: "Effective search",
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          text: "Venue comparison",
        },
        {
          icon: "heart",
          text: "Shortlist, save and share",
        },
        {
          icon: "envelope-open-text",
          text: "Fast enquiry process",
        },
        {
          icon: "newspaper",
          text: "News, offers and insights",
        },
      ]}
      type="corporate"
      successUrl="/corporate-registration-complete"
      formColor="#0077b5"
      bodyTitle="Why use EDGE to book your next corporate event?"
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "19,000 superb venues at your fingertips",
          content: (
            <div>
              <p>
                We pride ourselves on our venue knowledge and have created an
                extensive database to meet the needs of meeting and event
                planners.
              </p>
              <p>
                Our years of experience in planning events sets us in a great
                place.
              </p>
              <p>
                We understand what you're are looking for, whether it's a unique
                venue in <a href="/venues-in-london">London</a>, or a training
                room in <a href="/venues-in-manchester">Manchester</a>, we have
                them all. Not only that, our knowledge extends overseas to cover
                125 destinations in 45 countries. Our database consists of
                19,000 venues (and growing), so you'll be sure to find the
                perfect venue for your next meeting or event.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Simple and effective search functionality",
          content: (
            <div>
              <p>
                Simplicity is the key. We're aware how time conscious everyone
                is, especially when it comes to finding the time to search for
                that perfect venue.
              </p>
              <p>
                With two different search functions, we can quickly and easily
                help you to find the venue you're looking for. From our ‘simple'
                search, you can easily view the venues in a particular
                destination. Using our ‘advanced' search, by completing a few
                more fields, you'll really be able to narrow down this search
                and save time later on.
              </p>
            </div>
          ),
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          title: "Venue comparison",
          content: (
            <div>
              <p>Can't decide on what venue to use? Have a few favourites?</p>
              <p>
                Our side-by-side comparison tool allows you to easily compare
                your preferred choice of venues to determine which one will work
                best for your event. You can compare the size of meeting rooms,
                against number of bedrooms and distance from airport – all
                helping you make informed decisions for your meetings and
                events.
              </p>
            </div>
          ),
        },
        {
          icon: "heart",
          title: "Shortlist, save and share",
          content: (
            <div>
              <p>
                Not ready to make the booking yet? Or want to continue your
                search at a later date?
              </p>
              <p>
                Not to worry we have this covered! As a subscriber, EDGE Venues
                allows you to make a shortlist, save your searches and share
                with colleagues. Plus you can come back at any time and pick up
                where you left off.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Fast enquiry process",
          content: (
            <div>
              <p>
                At EDGE, we don't just stop at the 'search' stage, and once
                you've found that perfect venue(s), you now need to know if it's
                available.
              </p>
              <p>
                Our enquiry process allows you to express your interest in
                multiple venues at a few clicks of a button. The chosen venues
                will then come back to you directly, to share availability and
                confirm costings to allow you to continue your planning. It
                really couldn't be easier.
              </p>
            </div>
          ),
        },
        {
          icon: "newspaper",
          title: "News, offers and insights",
          content: (
            <div>
              <p>
                We want to keep you informed of all the latest industry news.
                Our EDGE editors are continuously looking for new stories and
                features.
              </p>
              <p>
                Whether it's new openings, refurbishment, new event concepts or
                industry trends, consider EDGE Venues your central hub of
                information for everything meetings and events. Not only that,
                we have a fantastic selection of offers available for you to
                take advantage of when considering your choice of venue.
              </p>
            </div>
          ),
        },
      ]}
      splitFeatureTitle="Find the perfect venue for your next meeting or corporate event"
      splitFeatureList={[
        "Growing global database of hotels and venues",
        "Save time venue finding",
        "Be the first in the know",
        "Engagement platform",
      ]}
    />
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    ...state.agency,
  };
};

const mapDispatchToProps = {
  fetchAgencyBySlug,
  agencyUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateEventBuyers);
