import React from "react";
import TrapezeHeader from "Components/TrapezeHeader";
import { FaExchangeAlt } from "react-icons/fa";
import { ButtonOutlined } from "Components/Button";
import { Link } from "react-router-dom";

const ShortlistUnlogged = () => {
  return (
    <div className="bg-white mb-5 hidden lg:block">
      <TrapezeHeader
        bgColor="edge-blue"
        title="Compare / Shortlist"
        icon={<FaExchangeAlt className="mr-2 text-white text-lg" />}
        bigText
        textLeft
      />
      <div className="py-3 px-4">
        <p className="text-sm">
          As a registered user, you can add venues to shortlists and compare
          them before submitting an enquiry or an RFP.
        </p>
        <img src="/img/add-shortlist-sample.png" alt="Sample shortlist" />
        <div className="mt-3">
          <Link to="/register">
            <ButtonOutlined classes="border-2 border-green text-green w-full">
              Register online
            </ButtonOutlined>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShortlistUnlogged;
