import React from 'react';
import {Link} from 'react-router-dom';

const FooterLink = ({children, href, ...rest}) => {
    return (
        <Link to={href} {...rest} className="text-off-white block mb-1">
            {children}
        </Link>
    )
}

export default FooterLink;