import React from "react";
import { withRouter } from "react-router-dom";
import RegistrationForm from "Components/RegistrationForm";
import SplitFeature from "Components/SplitFeature";

const RegistrationLandingPage = ({
                                   img,
                                   history,
                                   location,
                                   title,
                                   subtitle,
                                   bannerFeatures,
                                   bodyFeatures,
                                   bodyTitle,
                                   type,
                                   successUrl,
                                   formColor,
                                   splitFeatureTitle,
                                   splitFeatureList,
                                   agency,
                                 }) => {
  return (
    <div className="container">
      <div
        id="join"
        className="p-3 md:p-8 text-white md:flex"
        style={{
          backgroundImage: `url(${img})`,
        }}
      >
        <div className="md:w-3/5 md:mr-24">
          {agency && agency.code !== "edge" && agency.logo_file ? (
            <div className={"p-3 bg-white inline-block mb-7"}>
              <img src={`${window.API}/brands/images/${agency.id}`} alt={`${agency.name} Logo`} />
            </div>
          ) : null}
          <h1 className="mb-5">{title}</h1>
          <h2 className="mb-10">{subtitle}</h2>
          <div className="row">
            {bannerFeatures.map((feature, i) => (
              <div className="col col-w-full md:col-w-1/3" key={i}>
                <span className="text-center text-4xl">
                  {typeof feature.icon === "string" ? (
                    <i className={`fal fa-${feature.icon}  block mb-4`}></i>
                  ) : (
                    feature.icon
                  )}
                </span>
                <h3
                  className="text-center mb-10"
                  dangerouslySetInnerHTML={{ __html: feature.text }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-2/5 p-3 md:p-10" style={{ background: formColor }}>
          <RegistrationForm
            formType={type}
            onRegister={() => history.push(successUrl)}
            agency={agency}
          />
        </div>
      </div>

      <h2 className="block text-center mt-10 mb-6">{bodyTitle}</h2>

      <div className="row">
        {bodyFeatures.map((feature, i) => (
          <div className="col col-w-full md:col-w-1/3 mb-6" key={i}>
            <div className="bg-white h-full p-12">
              <span className="text-center text-5xl">
                {typeof feature.icon === "string" ? (
                  <i className={`fal fa-${feature.icon}  block mb-4`}></i>
                ) : (
                  feature.icon
                )}
              </span>
              <h3 className="text-center mb-5">{feature.title}</h3>
              {feature.content}
            </div>
          </div>
        ))}
      </div>

      <SplitFeature
        img="/img/hotel-placeholder.jpg"
        title={splitFeatureTitle}
        linkText="Register now"
        link={"join"}
        text={
          <div>
            <ul>
              {splitFeatureList.map(item => (
                <li className="mb-2" key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        }
      />
    </div>
  );
};

export default withRouter(RegistrationLandingPage);
