import React, { Fragment, useState } from "react";
import {connect} from 'react-redux';
import { string, node } from "prop-types";
import ContentFeature from "Components/ContentFeature";
// import ExpandableText from "Components/ExpandableText";
import ReadMoreText from "Components/ReadMoreText";
import LinkedIcon from "Components/LinkedIcon";
import { ButtonLink, ButtonBrand } from "Components/Button";
import {
  FaPhone,
  FaEnvelope,
  FaRegPlusSquare,
  FaRegMinusSquare,
  FaInstagram,
  FaTwitter,
  FaTripadvisor,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { isAgencyOrHotel } from "modules/auth";
import LinkedButton from "../../Components/LinkedButton";
import LinkedBrandButton from "../../Components/LinkedBrandButton";
import {Feature} from "./KeyInformation";
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";
import { GOOGLE_MAPS_API_KEY } from "../../constants";

const SocialIcons = ({mobile, instagramLink, twitterLink, tripadvisorLink, venueId}) => {
  return (
    <div className={`pt-2 px-3 text-xl ${mobile &&
    "border-b border-light-blue-grey flex items-center justify-between pb-2"}`}>
      <div className="flex justify-center lg:justify-start">
        <Feature href={instagramLink ? instagramLink : ''} target="_blank" onClick={() => {
          venueInteraction({
            type: VENUE_TAGS.SOCIALS.VIEW_INSTAGRAM,
            id: venueId
          })
        }}>
          <FaInstagram className="text-sm hover:text-edge-blue" />
        </Feature>
        <Feature href={twitterLink ? twitterLink : ''} target="_blank" onClick={() => {
          venueInteraction({
            type: VENUE_TAGS.SOCIALS.VIEW_TWITTER,
            id: venueId
          })
        }}>
          <FaTwitter className="text-sm hover:text-edge-blue" />
        </Feature>
        <Feature href={tripadvisorLink ? tripadvisorLink : ''} target="_blank" onClick={() => {
          venueInteraction({
            type: VENUE_TAGS.SOCIALS.VIEW_TRIPADVISOR,
            id: venueId
          })
        }}>
          <FaTripadvisor className="text-sm hover:text-edge-blue" />
        </Feature>
      </div>
    </div>
  )
}

const About = ({
  info,
  location,
  description,
  anchor,
  address,
  mobile,
  venue,
  impression_id,
  contacts,
  isCommitted,
  venueInteraction,
  twitterLink,
  instagramLink,
  tripadvisorLink
}) => {
  const nonAgency = useSelector(state => !isAgencyOrHotel(state));

  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const contactsList = () => {
    if (nonAgency && !isCommitted) return [];

    if (nonAgency)
      return contacts.filter(
        concat => concat.contact_type === "Conference & Events"
      );
    return contacts;
  };
  const list = contactsList();
  return (
    <Fragment>
      <span className="anchor sr-only" id={anchor}>
        Details section
      </span>
      <section className="bg-white text-lg mb-3 shadow">
        {/* <div className="pt-5 px-3 flex text-base">
            <span className="font-normal">Edge User Rating</span>
            <div className="ml-3 flex items-center">
                <FaStar className="text-lg text-yellow" />
                <FaStar className="text-lg text-yellow" />
                <FaStar className="text-lg text-yellow" />
                <FaStar className="text-lg text-yellow" />
                <FaRegStar className="text-lg text-yellow" />
            </div>
            <a href="#" className="text-edge-blue underline ml-3 font-normal">3 reviews</a>
          </div> */}
        <h2
          className={`pt-4 px-3 text-xl ${mobile &&
            "border-b border-light-blue-grey flex items-center justify-between pb-2"}`}
          onClick={toggle}
        >
          Venue Details
          {mobile && !expanded && <FaRegPlusSquare className="text-base" />}
          {mobile && expanded && <FaRegMinusSquare className="text-base" />}
        </h2>
        <SocialIcons
          venueId={venue.id}
          mobile={mobile}
          twitterLink={twitterLink}
          instagramLink={instagramLink}
          tripadvisorLink={tripadvisorLink}
        />
        {(!mobile || (mobile && expanded)) && (
          <div className="row">
            <div className="col col-w-full lg:col-w-1/2">
              {location && (
                <ContentFeature classes="lg:col px-3 mb-3">
                  <ReadMoreText>{location}</ReadMoreText>
                </ContentFeature>
              )}

              {description && mobile && (
                <div className="pt-6">
                  <ContentFeature
                    title="About the venue"
                    classes="lg:col px-3 mb-3"
                  >
                    <ReadMoreText>{description}</ReadMoreText>
                  </ContentFeature>
                </div>
              )}
              {info  && mobile  && (
                  <div className="pt-6">
                    <ContentFeature title="About the brand" classes="lg:col px-3 mb-3">
                      <ReadMoreText>{info}</ReadMoreText>
                    </ContentFeature>
                  </div>
              )}
              <div className="bg-light-grey py-6">
                <div className="mb-6 col col-w-full flex">
                  <div className="w-1/10">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <div className="w-9/10">
                    <span className="font-normal text-base">{address}</span>
                    <ButtonLink
                      Element="a"
                      classes="text-edge-blue font-normal text-base ml-2 text-sm"
                      href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                        address
                      )}`}
                      target="_blank"
                      onClick={() => {
                        venueInteraction({
                          data_score: venue.data_score,
                          impression_id: venue.impression_id,
                          type: VENUE_TAGS.VIEW_GET_DIRECTIONS,
                          id: venue.id
                        })
                      }}
                    >
                      Get directions
                    </ButtonLink>
                  </div>
                </div>
                {venue.number_of_miles_from_nearest_airport && (
                  <div className="mb-6 flex col col-w-full">
                    <div className="w-1/10">
                      <i
                        className="fas fa-plane-departure"
                        title="Travel time to nearest airport when driving"
                      />
                    </div>
                    <div className="w-9/10">
                      <span className="font-normal text-base">{`${venue.nearest_airport} (${venue.number_of_miles_from_nearest_airport} miles)`}</span>
                    </div>
                  </div>
                )}
                <div className="md:flex flex-wrap">
                  {venue.website && (
                    <div className="mr-8 mb-4 md:mb-0 flex col col-w-1/2 xxl:col-w-1/3">
                      <div className="w-1/5 xxl:w-1/3">
                        <LinkedIcon
                          auth
                          link={venue.website}
                          newWindow
                          className="mr-2"
                          iconClassName="fas fa-globe text-black"
                          venueInteraction={{
                            id: venue.id,
                            data_score: venue.data_score,
                            impression_id: impression_id,
                            type: VENUE_TAGS.WEBSITE.VIEW_WEBSITE,
                          }}
                        />
                      </div>
                      <div>
                        <ButtonLink
                          Element="a"
                          href={venue.website}
                          target="_blank"
                          classes="text-edge-blue text-sm font-normal text-base"
                          onClick={() => {
                            venueInteraction({
                              id: venue.id,
                                data_score: venue.data_score,
                                impression_id: impression_id,
                                type: VENUE_TAGS.WEBSITE.VIEW_WEBSITE,
                            })}
                          }
                        >
                          Visit website
                        </ButtonLink>
                      </div>
                    </div>
                  )}
                  {venue.phone && (
                    <div className="mr-8 mb-4 md:mb-0 flex col col-w-1/2 xxl:col-w-1/3">
                      <div className="w-1/5 xxl:w-1/3">
                        <i className={'fas fa-phone text-black'} />
                      </div>
                      <LinkedButton
                          auth
                          phone
                          link={venue.phone}
                          newWindow
                          className="mr-2"
                          iconClassName="fas fa-phone text-black"
                          googleTagEvent="call_venue_by_phone"
                          venueInteraction={{
                            id: venue.id,
                            data_score: venue.data_score,
                            impression_id: impression_id,
                            type: VENUE_TAGS.TELEPHONE.CLICK_CALL_VENUE,
                          }}
                          text={'Call venue'}
                          textOnShow={venue.phone}
                      />
                    </div>
                  )}
                  {venue.email && (
                    <div className="mr-8 mb-4 md:mb-0 flex col col-w-1/2 mt-4 xxl:mt-0 xxl:col-w-1/3">
                      <div className="w-1/5 xxl:w-1/3">
                          <i className={'fas fa-envelope text-black'} />
                      </div>
                      <ButtonLink
                        Element="a"
                        href={`mailto:${venue.email}`}
                        classes="text-edge-blue text-sm font-normal text-base"
                        onClick={() => {
                          venueInteraction({
                            type: VENUE_TAGS.EMAIL.CLICK_EMAIL_VENUE,
                            id: venue.id,
                            data_score: venue.data_score,
                            impression_id: impression_id,
                          })
                        }}
                      >
                        Send email
                      </ButtonLink>
                    </div>
                  )}
                </div>
              </div>
              {contacts.length > 0 && !mobile && (
                <>
                  {list.map(contact => (
                    <div className="p-3 flex items-center" key={contact.id}>
                      <div className="w-40 xxl:w-48">
                        <span className="font-bold uppercase text-sm">
                          {contact.contact_type ||
                            contact.job_title ||
                            contact.department}
                        </span>
                      </div>
                      {contact.email && (
                        <ButtonBrand
                          Element="a"
                          href={`mailto:${contact.email}`}
                          classes="rounded-sm mr-4 p-3"
                          onClick={() => {
                            venueInteraction({
                              type: VENUE_TAGS.EMAIL.CLICK_CONTACT_EMAIL,
                              id: venue.id,
                              data_score: venue.data_score,
                              impression_id: impression_id,
                            })
                          }}
                        >
                          <FaEnvelope className="text-xl text-white" />
                        </ButtonBrand>
                      )}
                      {contact.phone && (
                          <LinkedBrandButton
                              auth
                              phone
                              link={venue.phone}
                              newWindow
                              className="mr-2"
                              iconClassName="fas fa-phone text-black"
                              googleTagEvent="call_venue_by_phone"
                              venueInteraction={{
                                id: venue.id,
                                data_score: venue.data_score,
                                impression_id: impression_id,
                                type: VENUE_TAGS.TELEPHONE.CLICK_CONTACT_PHONE,
                              }}

                              icon={ <FaPhone className="text-xl text-white" />}
                              textOnShow={contact.phone}
                          />
                      )}
                    </div>
                  ))}
                  {nonAgency && (
                    <div className="p-3 bg-light-grey flex items-center">
                      <div className="w-40 xxl:w-48">
                        <span className="font-bold uppercase text-sm">
                          Booking line
                        </span>
                      </div>
                      <ButtonBrand
                        Element="a"
                        href="tel:01780484051"
                        classes="rounded-sm flex items-center"
                      >
                        <FaPhone className="text-xl text-white mr-3" />
                        <span>01780 484051</span>
                      </ButtonBrand>
                    </div>
                  )}
                </>
              )}
              {description && !mobile && (
                <div className="pt-6">
                  <ContentFeature
                    title="About the venue"
                    classes="lg:col px-3 mb-3"
                  >
                    <ReadMoreText>{description}</ReadMoreText>
                  </ContentFeature>
                </div>
              )}
              {info  && !mobile  && (
                  <div className="pt-6">
                    <ContentFeature title="About the brand" classes="lg:col px-3 mb-3">
                      <ReadMoreText>{info}</ReadMoreText>
                    </ContentFeature>
                  </div>
              )}
            </div>
            <div className="col col-w-full lg:col-w-1/2">
              <iframe
                title="Google map"
                className="w-full h-full"
                frameBorder="0"
                scrolling="no"
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${venue.lat},${venue.long}&zoom=14`}
              />
            </div>
          </div>
        )}
        <div className="lg:flex lg:-mx-3">
          {/* {location && (
            <ContentFeature title="Location" classes="lg:col lg:px-3 mb-3">
              {mobile ? (
                <p className="font-normal">
                  <i className="fas fa-map-marker-alt mr-2" />
                  {address}
                </p>
              ) : null}
              <ExpandableText>{location}</ExpandableText>
            </ContentFeature>
          )} */}
        </div>
      </section>
    </Fragment>
  );
}

About.propTypes = {
  anchor: string.isRequired,
  info: node,
  location: node,
  description: node,
};

export default connect(null, {venueInteraction})(About)