import React from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";

const Agents = () => {
  return (
    <RegistrationLandingPage
      img="/img/landing/agency-banner.jpg"
      title="Find the best venues for meeting spaces for your clients' next event"
      subtitle="We're here to connect you with venues globally to help you plan and
    deliver great meetings and events venues for your clients."
      bannerFeatures={[
        {
          icon: "globe-americas",
          text: "19,000 venues",
        },
        {
          icon: "search",
          text: "Effective search",
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          text: "Venue comparison",
        },
        {
          icon: "heart",
          text: "Shortlist, save and share",
        },
        {
          icon: "envelope-open-text",
          text: "Fast enquiry process",
        },
        {
          icon: "handshake",
          text: "Commission deals and <br /> FAM trips",
        },
      ]}
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "19,000 superb venues at your fingertips",
          content: (
            <div>
              <p>
                We pride ourselves on our venue knowledge and have created an
                extensive database to meet the needs of meeting and event
                planners.
              </p>
              <p>
                Our background means we truly understand what you're looking for
                in venues for their events. Whether it's a unique venue in{" "}
                <a href="/venues-in-london">London</a>, or a training room in{" "}
                <a href="/venues-in-manchester">Manchester</a>, we have them
                all. Not only that, our knowledge extends overseas to cover 125
                destinations in 45 countries. Our database consists of 19,000
                venues (and growing), so you'll be sure to find the perfect
                venue for your client for their next event.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Effective search functionality",
          content: (
            <div>
              <p>
                Simplicity is the key. We're aware how time conscious you are,
                especially when it comes to finding the time to search for that
                perfect venue.
              </p>
              <p>
                With two different search functions, we can quickly and easily
                help you to find the venue you're looking for. Our ‘simple'
                search, you can easily view the venues in a particular
                destination. Using our 'advanced' search, by completing a few
                more fields, you'll really be able to narrow down this search
                and save time later on.
              </p>
            </div>
          ),
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          title: "Venue comparison",
          content: (
            <div>
              <p>Can't decide on what venue to use? Have a few favourites?</p>
              <p>
                Our side-by-side comparison tool allows you to easily compare
                your preferred choice of venues to determine which one will work
                best for your event for your client. You can compare the size of
                meeting rooms, against number of bedrooms and distance from
                airport – all helping you make informed decisions for your
                clients' meetings and events.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Fast enquiry process",
          content: (
            <div>
              <p>
                At EDGE, we don't just stop at the 'search' stage, and once
                you've found that perfect venue(s), you now need to know if it's
                available.
              </p>
              <p>
                Our enquiry process allows you to express your interest in
                multiple venues at a few clicks of a button. The chosen venues
                will then come back to you directly, share availability and
                confirm costings to allow you to continue your planning. It
                really couldn't be easier.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "News, offers, insights",
          content: (
            <div>
              <p>
                We want to keep you informed of all the latest industry news.
                Our EDGE editors are continuously looking for new stories and
                features.
              </p>
              <p>
                Whether it's new openings, refurbishment, new event concepts or
                industry trends, consider EDGE Venues your central hub of
                information for everything meetings and events. Not only that we
                have a fantastic selection of offers available for you to take
                advantage of when considering your choice of venue.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Commission deals and FAM trips",
          content: (
            <div>
              <p>
                It's important you get the most out of EDGE Venues and that's
                why we have a wide range of commission offers available to
                ensure you're maximising your revenue stream. We also believe in
                giving you the best of experiences. There are plenty of FAM
                trips and Agents Rates that will allow you to experience the
                venues for yourself, giving you first-hand knowledge before
                putting it forward to your clients.
              </p>
            </div>
          ),
        },
      ]}
      bodyTitle="Why use EDGE to book your next corporate event?"
      type="agency"
      successUrl="/agency-registration-complete"
      formColor="#8c8c8b"
      splitFeatureTitle="Find the perfect venue for your next meeting or corporate event"
      splitFeatureList={[
        "Growing global database of hotels and venues",
        "Save time venue finding",
        "Engagement platform",
        "Exclusive commission deals",
      ]}
    />
  );
};

export default Agents;
