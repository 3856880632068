import React, { memo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { getWidth } from "redux-window";
import { ReactComponent as SliderPrev } from "svg/slider-prev.svg";
import BackgroundImage from "../General/BackgroundImage";

const PrevArrow = ({ onClick }) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <SliderPrev
      className="absolute-center pointer z-20 hover:text-green transition text-white"
      style={{ left: mobile ? "10px" : "20px" }}
      onClick={onClick}
    />
  );
};

const NextArrow = ({ onClick }) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <SliderPrev
      className="absolute-center-y rotate-180-center-y pointer z-20 text-white hover:text-green transition"
      style={{ right: mobile ? "10px" : "20px" }}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ImagesCarouselItem = ({ item }) => {
  return (
      <BackgroundImage className={'bg-cover bg-center quick-view-height'}
                       imagePath={item.path}
                       edits={{
                         "resize": {
                           "width": 527,
                           "height": 400,
                           "fit": "cover"
                         }}}>
        {item.caption && (
            <div
                className="absolute py-2 px-4 w-full text-white font-normal"
                style={{ bottom: 10, background: "rgba(0,0,0,.75)" }}
            >
              {item.caption}
            </div>
        )}
      </BackgroundImage>
    // <div
    //   className="relative"
    //   style={{
    //     height: 400,
    //     backgroundImage: `url(${item.url.replace('stage-api','api')})`,
    //     backgroundSize: "cover",
    //   }}
    // >
    //   {item.caption && (
    //     <div
    //       className="absolute py-2 px-4 w-full text-white font-normal"
    //       style={{ bottom: 10, background: "rgba(0,0,0,.75)" }}
    //     >
    //       {item.caption}
    //     </div>
    //   )}
    // </div>
  );
};

const ImagesCarousel = ({ images }) => {
  const imagesList = images.slice(0, 10);

  return (
    <Slider {...settings}>
      {imagesList &&
        imagesList.map(item => {
          if (item.url !== null) {
            return <ImagesCarouselItem item={item} key={item.id} />;
          }
          return null;

        })}
    </Slider>
  );
};

export default memo(ImagesCarousel);
