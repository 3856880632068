import React from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import { string, bool, any  } from "prop-types";
import { isLoggedIn } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import { pushEvent } from "../modules/google-tag-manager";
import {ButtonBrand} from "./Button";

class LinkedButton extends React.Component {
    static propTypes = {
        iconClassName: string.isRequired,
        link: string,
        className: string,
        phone: bool,
        email: bool,
        newWindow: bool,
        auth: bool,
        clickType: string,
        text: any
    };

    state = {
        show: false,
    };

    handleClick = e => {
        if (this.props.auth && !this.props.isLoggedIn) {
            e.preventDefault();
            this.props.history.push(
                `/login?return=${encodeURI(
                    this.props.location.pathname
                )}&logInToProceed=1`
            );
            return;
        }
        if (this.props.venueInteraction) {
            this.props.track(this.props.venueInteraction);
        }

        if (this.props.googleTagEvent) {
            pushEvent(this.props.googleTagEvent);
        }

        if (this.props.phone) {
            e.preventDefault();
            this.setState({
                show: !this.state.show,
            });
        }
    };

    render() {
        const {
            newWindow,
            link,
            icon,
            phone,
            email,
            textOnShow,
        } = this.props;
        let prefix = "";
        if (phone) {
            prefix += "tel:";
        } else if (email) {
            prefix += "mailto:";
        }

        if(this.state.show){
            return(

                <ButtonBrand
                    Element="a"
                    href={`tel:${textOnShow}`}
                    classes="rounded-sm flex items-center"
                >
                    {textOnShow}
                </ButtonBrand>
            )
        } else {
            return(
                <a
                    href={prefix + link}
                    target={newWindow && "_blank"}
                    rel={newWindow && "noopener noreferer"}
                    onClick={this.handleClick}
                >
                    <ButtonBrand
                        Element="span"
                        href="tel:01780484051"
                        classes="rounded-sm flex items-center"
                    >
                        {icon ? icon : null }

                    </ButtonBrand>

                </a>
            )
        }
    }
}

export default withRouter(
    connect(
        state => ({
            isLoggedIn: isLoggedIn(state),
        }),
        { track: venueInteraction }
    )(LinkedButton)
);
