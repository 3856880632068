import React from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";

const Venues = () => {
  return (
    <RegistrationLandingPage
      img="/img/landing/conference-banner.jpg"
      title="Maximise the exposure of your venue through EDGE"
      bannerFeatures={[
        {
          icon: "globe-americas",
          text: "Simple and effective RFP process",
        },
        {
          icon: "keynote",
          text: "Unlimited photos and virtual tours",
        },
        {
          icon: "clock",
          text: "Buy it now",
        },
        {
          icon: "server",
          text: "Manage your own data",
        },
        {
          icon: "comments-alt",
          text: "Engagement platform",
        },
        {
          icon: "chart-pie",
          text: "Real-time analytics",
        },
      ]}
      type="venue"
      successUrl="/venue-registration-complete"
      formColor="#847b68"
      bodyTitle="Why use EDGE for your venue?"
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "Simple and effective RFP process",
          content: (
            <div>
              <p>
                Simplicity is the key. We're aware how time conscious everyone
                is, especially when it comes to finding the time to search for
                that perfect venue. Through our RFP process, buyers are able to
                submit requests to multiple venues. These RFPs come straight to
                you for completion and once completed buyers will be able to
                review, accept and decline their chosen venues.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Unlimited photos and virtual tours",
          content: (
            <div>
              <p>
                People buy from pictures and visual aids. On EDGE you have the
                ability to upload unlimited photos to your own gallery as well
                as a link to your virtual tour. Ensure your images are of
                high-quality to help promote your property.
              </p>
            </div>
          ),
        },
        {
          icon: "tags",
          title: "Buy it now",
          content: (
            <div>
              <p>
                Give meeting and event planners the option to 'buy it now' on
                meeting rooms for 10 delegates and under. State your prices and
                planners can quickly and easily complete an enquiry form and
                providing you have the availability, they’ll be able to book
                your venue direct.
              </p>
            </div>
          ),
        },
        {
          icon: "server",
          title: "Manage your own data",
          content: (
            <div>
              <p>
                With your own login details, you'll be able to update and manage
                your own data that is displayed on EDGE. With 125 fields to
                complete, you're able to personalise your profile to make your
                property stand out from the crowd.
              </p>
            </div>
          ),
        },
        {
          icon: "comments-alt",
          title: "Engagement platform",
          content: (
            <div>
              <p>
                EDGE is seen as the place to go for news, offers, exclusive
                deals for its users. This is your platform to share your latest
                venue news, special offers, commission deals, FAM trips and
                Agents rates, all of which is shared and communicated with the
                EDGE Venues community.
              </p>
            </div>
          ),
        },
        {
          icon: "chart-pie",
          title: "Real-time analytics",
          content: (
            <div>
              <p>
                See how your venue is performing in real-time on your own
                personal dashboard. Our analytics will show you the performance
                of your page with number of impressions, clicks, audience type,
                how people are interacting with you and the value of enquiries
                you are receiving.
              </p>
            </div>
          ),
        },
      ]}
      splitFeatureTitle="Be part of the EDGE Venues Journey"
      splitFeatureList={[
        "Engage with our Agency community",
        "Join our quarterly Business Intelligence Reviews",
        "Manage your own data and see real-time analytics",
      ]}
    />
  );
};

export default Venues;
