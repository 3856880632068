import React, {useState} from "react";
import FeaturedSection from "Components/FeaturedSection";
import Input from "../Components/Form/Input";

const WorldwideVenueLocations = () => {
  const [location, setLocation] = useState("");
  return (
    <div className="container">
      <div className="row">
        <div className="col col-w-full md:col-w-1/2">
          <h1 className="mt-8 mb-5">EDGE Worldwide Venue Locations</h1>
        </div>
        <div className="col col-w-full md:col-w-1/2">
          <Input
            input={{
              onChange: e => setLocation(e.target.value),
              value: location,
            }}
            labelClassName="sr-only"
            iconRight="/img/search-icon.svg"
            meta={{}}
            name="term"
            label={`Search Locations`}
            placeholder={`Search Locations`}
            wrapperClassName="mt-8 mb-5 w-full"
          />
        </div>
      </div>

      <FeaturedSection
        url={`${window.API}/destinations-list?worldwide=1&shallow=1`}
        sort={(a, b) => {
          const textA = a.city.toUpperCase();
          const textB = b.city.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }}
        filter={(city) => {
          return city ? city.city.toLowerCase().includes(location.toLowerCase()) : true;
        }}
      />
      <div className="mb-5"/>
    </div>
  );
};

export default WorldwideVenueLocations;
