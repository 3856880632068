import React from "react";
import { connect } from "react-redux";
import { Circle, Marker, GoogleMap, withGoogleMap } from "react-google-maps";
import { change, getFormValues } from "redux-form";
import prettyRadius from "modules/pretty-radius";

const style = {
  strokeColor: "rgb(0, 153, 255)",
  strokeOpacity: 0.4,
  strokeWeight: 2,
  fillColor: "rgb(0, 153, 255)",
  fillOpacity: 0.2,
  zIndex: 1,
};

class Map extends React.Component {
  state = {
    markers: [],
  };

  constructor(props) {
    super(props);
    this.setRef = {
      map: e => (this.map = e),
      circle: e => (this.circle = e),
      searchBox: e => (this.searchBox = e),
    };
  }

  zoom = () => {
    if (!this.props.lat && !this.props.lng) {
      return 6;
    }
    switch (prettyRadius(this.props.radius)) {
      case 0.25:
        return 15;
      case 0.5:
        return 14;
      case 1:
      case 2:
        return 13;
      case 3:
      case 4:
        return 12;
      case 5:
      case 6:
      case 7:
        return 11;
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        return 10;
      case 17:
      case 18:
      case 19:
      case 20:
        return 9;
      default:
        return 13;
    }
  };

  render() {
    return (
      <GoogleMap
        ref={this.setRef.map}
        zoom={this.zoom()}
        center={
          new window.google.maps.LatLng(
            parseFloat(this.props.lat) || 52.6445885,
            parseFloat(this.props.lng) || -0.5006198
          )
        }
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {this.props.lat && this.props.lng ? (
          <Marker
            position={{
              lat: parseFloat(this.props.lat),
              lng: parseFloat(this.props.lng),
            }}
          />
        ) : null}
        {this.props.results.slice(0, 100).map(result => (
          <Marker
            key={result.id}
            icon="/img/hotel-marker.svg"
            position={{
              lat: parseFloat(result.lat),
              lng: parseFloat(result.lng),
            }}
          />
        ))}
        {this.props.lat && this.props.lng ? (
          <Circle
            center={
              new window.google.maps.LatLng(
                parseFloat(this.props.lat) || 52.6445885,
                parseFloat(this.props.lng) || -0.5006198
              )
            }
            radius={parseFloat(this.props.radius)}
            defaultEditable={false}
            ref={this.setRef.circle}
            options={style}
          />
        ) : null}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(
  connect(
    state => {
      const v = getFormValues("location-search")(state) || {};
      return {
        radius: v.radius,
        lat: v.lat,
        lng: v.lng,
      };
    },
    { change }
  )(Map)
);
